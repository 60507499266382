
















import Vue from 'vue'
import Content from '../components/Content.vue'
import SavedSignatures from '../components/SavedSignatures.vue'
import Form from '../components/Form.vue'

export default Vue.extend({
  data: () => {
    return {
      password: '',
      showSavedSignatures: false,
      showModal: true
    }
  },
  methods: {
    passwordSubmit: function (data) {
      const password = JSON.parse(data).password
      this.password = password
      this.showModal = false
      this.showSavedSignatures = true
    },
    openModal: function () {
      this.showSavedSignatures = false
      this.showModal = true
    }
  },
  components: {
    Content,
    SavedSignatures,
    Form
  }
})
